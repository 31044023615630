@import "variables";
@import "util";

html {
  margin: 0;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
    @media only screen and (max-width: 550px) {
        width: 500px;
        img, figure {
            max-width: 500px;
        }
    }

    @media only screen and (max-width: 500px) {
        width: 450px;
        img, figure {
            max-width: 450px;
        }
    }

    @media only screen and (max-width: 450px) {
        width: 400px;
        img, figure {
            max-width: 400px;
        }
    }

    @media only screen and (max-width: 400px) {
        width: 350px;
        img, figure {
            max-width: 350px;
        }
    }

    width: $page-width;
    margin: 5%;
    margin-bottom: 0px;
    flex: 1;
    background-color: $color-background;
    color: $color-text;
    font-family: $font-family-body;
    font-size: $font-size;
    font-weight: 400;
    text-rendering: geometricPrecision;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-header;
    font-style: normal;
    font-weight: 700;
}

input,
button,
select,
textarea {
    font-family: inherit;
}

table td, table td * {
    text-align: left;
    vertical-align: top;
}

td {
    min-width: 80px;
    white-space: nowrap;
    padding-right: 0.5em;
}

@import "partial/header";
@import "partial/footer";

.hero {
    font-family: $font-family-header;
    font-size: $hero-size;
    font-style: normal;
    font-weight: 700;
}

.content {
    width: 100%;
    line-height: $line-height;
    float: right;

    figure {
        margin: 0px;

        img, p {
            font-style: italic;
            width: 100%;
        }
    }

    p {
        margin-block-start: 1em;
        margin-block-end: 2em;
    }

    a {
        color: $color-background;
        background: $color-accent;
        text-decoration: none;
        padding: 0.15em 0.25em;
    }
}

.page-list {
    padding-inline-start: 0px;

    li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        line-height: 1;

        a {
            color: $color-text;
            background: $color-background;
            text-decoration: none;
        }
    }
};

pre {
  overflow-x: auto;
  padding: 15px 15px 10px 15px;
  font-size: 0.8em;
}

iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
}
