@import "../variables";

#header {
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 100%;

    #logo {
        float: left;
        cursor: pointer;

        img {
            height: 45px;
        }
    }

    #nav {
        float: right;

        ul {
            li {
                display: inline-block;
                padding-right: 10px;
            }

            li > a {
                color: $color-text;
                background: $color-background;
                text-decoration: none;
            }
        }
    }
};
