$font-family-header: Palatino, Constantia, "Lucida Bright", Lucidabright,
                     "Lucida Serif", Lucida, "DejaVu Serif",
                     "Bitstream Vera Serif", "Liberation Serif", Georgia,
                     serif;

$font-family-body: -apple-system, BlinkMacSystemFont, "Avenir Next",
                   Avenir, "Nimbus Sans L", Roboto, Noto, "Segoe UI",
                   Arial, Helvetica, "Helvetica Neue", sans-serif;

$font-family-mono: monospace;
$font-family-footer: monospace;

$font-size: 16px;
$hero-size: 24px;
$line-height: 1.5;

$page-width: 500px;
$content-width: 420px;
$content-padding: 80px;

$color-background: #FFF;
$color-text: #000;
$color-accent: #4B32EC;
$color-meta: #666;
